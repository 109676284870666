exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aliados-tsx": () => import("./../../../src/pages/aliados.tsx" /* webpackChunkName: "component---src-pages-aliados-tsx" */),
  "component---src-pages-aviso-de-privacidad-tsx": () => import("./../../../src/pages/aviso-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-tsx" */),
  "component---src-pages-bbva-tsx": () => import("./../../../src/pages/bbva.tsx" /* webpackChunkName: "component---src-pages-bbva-tsx" */),
  "component---src-pages-citibanamex-2022-tsx": () => import("./../../../src/pages/citibanamex2022.tsx" /* webpackChunkName: "component---src-pages-citibanamex-2022-tsx" */),
  "component---src-pages-citibanamex-tsx": () => import("./../../../src/pages/citibanamex.tsx" /* webpackChunkName: "component---src-pages-citibanamex-tsx" */),
  "component---src-pages-cobertura-tsx": () => import("./../../../src/pages/cobertura.tsx" /* webpackChunkName: "component---src-pages-cobertura-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-cotizar-tsx": () => import("./../../../src/pages/cotizar.tsx" /* webpackChunkName: "component---src-pages-cotizar-tsx" */),
  "component---src-pages-historias-de-exito-tsx": () => import("./../../../src/pages/historias-de-exito.tsx" /* webpackChunkName: "component---src-pages-historias-de-exito-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-preguntas-frecuentes-tsx": () => import("./../../../src/pages/preguntas-frecuentes.tsx" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-tsx" */),
  "component---src-pages-prepara-tu-llamada-tsx": () => import("./../../../src/pages/prepara-tu-llamada.tsx" /* webpackChunkName: "component---src-pages-prepara-tu-llamada-tsx" */),
  "component---src-pages-propuesta-interactiva-tsx": () => import("./../../../src/pages/propuesta-interactiva.tsx" /* webpackChunkName: "component---src-pages-propuesta-interactiva-tsx" */),
  "component---src-pages-soluciones-hogar-tsx": () => import("./../../../src/pages/soluciones/hogar.tsx" /* webpackChunkName: "component---src-pages-soluciones-hogar-tsx" */),
  "component---src-pages-soluciones-industria-tsx": () => import("./../../../src/pages/soluciones/industria.tsx" /* webpackChunkName: "component---src-pages-soluciones-industria-tsx" */),
  "component---src-pages-soluciones-negocio-tsx": () => import("./../../../src/pages/soluciones/negocio.tsx" /* webpackChunkName: "component---src-pages-soluciones-negocio-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-templates-article-archive-tsx": () => import("./../../../src/templates/ArticleArchive.tsx" /* webpackChunkName: "component---src-templates-article-archive-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/Article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-channel-partner-cotizar-tsx": () => import("./../../../src/templates/ChannelPartnerCotizar.tsx" /* webpackChunkName: "component---src-templates-channel-partner-cotizar-tsx" */),
  "component---src-templates-success-story-tsx": () => import("./../../../src/templates/SuccessStory.tsx" /* webpackChunkName: "component---src-templates-success-story-tsx" */)
}

