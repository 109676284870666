import React from "react";
import once from "lodash/once";
import "url-search-params-polyfill";
import "whatwg-fetch";
import "./src/styles/global.css";
import {
  AnalyticsProvider,
  capture,
  init,
  persistUtmTags,
} from "./src/common/analytics";

const initAnalyticsOnce = once(() => {
  const enabled =
    window.location.hostname !== "127.0.0.1" &&
    window.location.hostname !== "localhost";

  init(enabled);
});

// On the initial load on the client, check for UTM tags and store them away in a cookie
export function onInitialClientRender() {
  persistUtmTags();
}

export function onClientEntry() {
  initAnalyticsOnce();
}

export function onRouteUpdate() {
  capture("$pageview");
}

// wrapRootElement allows there to be a posthog instance in the entire child tree of the entire gatsby app
export function wrapRootElement({ element }) {
  initAnalyticsOnce();
  return <AnalyticsProvider>{element}</AnalyticsProvider>;
}
